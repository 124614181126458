<template>
    <v-card
        class="summary-detail"
        :loading="loading"
    >
        <v-form
            ref="form"
            v-model="valid"
        >
            <v-row noGutters>
                <v-col
                    cols="12"
                    md="6"
                    lg="4"
                    class="summary-detail__column"
                >
                    <!-- RRA Number -->
                    <div class="data-group">
                        <div class="data-group__label">RRA Number</div>
                        <div class="data-group__data">{{ rraNumber }}</div>
                    </div>
                    <!-- Location -->
                    <div class="data-group">
                        <div class="data-group__label">Location</div>
                        <div class="data-group__data">
                            <DropdownLocationList
                                v-if="isCreate"
                                :value="location"
                                :rules="[ rules.requiredObject ]"
                                :disabled="loading"
                                dense
                                hideDetails
                                outlined
                                @input="( val ) => $emit( 'update:location', val )"
                            />
                            <div v-else>
                                {{ location.name }}
                            </div>
                        </div>
                    </div>
                    <!-- Create Date -->
                    <div class="data-group">
                        <div class="data-group__label">Create Date</div>
                        <div class="data-group__data">{{ createDate }}</div>
                    </div>
                    <!-- RRA Notes -->
                    <div class="data-group">
                        <div class="data-group__label">RRA Notes</div>
                        <div class="data-group__data">
                            <v-textarea
                                :value="notes"
                                :disabled="loading || isNotesDisabled"
                                outlined
                                dense
                                hideDetails
                                @input="( val ) => $emit( 'update:notes', val )"
                            />
                        </div>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                    lg="4"
                    class="summary-detail__column supplier"
                >
                    <!-- Supplier -->
                    <div class="data-group">
                        <div class="data-group__label">Supplier</div>
                        <div class="data-group__data">
                            <DropdownPrimarySupplier
                                v-if="isCreate"
                                :value="supplier"
                                :rules="[ rules.requiredObject ]"
                                :disabled="loading"
                                dense
                                hideDetails
                                @input="( val ) => $emit( 'update:supplier', val )"
                            />
                            <div v-else>
                                {{ supplier.name }}
                            </div>
                        </div>
                    </div>
                    <!-- Supplier Parameters -->
                    <div class="data-group">
                        <div class="data-group__label">Supplier Parameters</div>
                        <div class="data-group__data">
                            <div class="checkbox-group">
                                <v-icon class="checkbox-group__icon">
                                    {{ supplier.authRequired ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank' }}
                                </v-icon>
                                <span>Authorization required</span>
                            </div>
                            <div class="checkbox-group">
                                <v-icon class="checkbox-group__icon">
                                    {{ supplier.restockingFee ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank' }}
                                </v-icon>
                                <span>Possible restocking fee</span>
                            </div>
                        </div>
                    </div>
                    <!-- Supplier Notes -->
                    <div class="data-group">
                        <div class="data-group__label">Supplier Notes</div>
                        <div class="data-group__data supplier__note" v-html="supplier.returnNote"></div>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    lg="4"
                    class="summary-detail__column"
                >
                    <v-row noGutters>
                        <v-col
                            cols="12"
                            md="6"
                            lg="12"
                        >
                        <!-- Status -->
                        <div class="data-group">
                            <div class="data-group__label">Status</div>
                            <div class="data-group__data">
                                <!-- Status -->
                                <div class="po-status">
                                    <v-autocomplete
                                        :value="status"
                                        :items="statusList"
                                        :disabled="isCreate || loading || isStatusDisabled"
                                        :appendIcon="( isStatusDisabled ? 'mdi-truck' : '' )"
                                        dense
                                        hideDetails
                                        outlined
                                        @input="( val ) => $emit( 'update:status', val )"
                                    />
                                </div>
                            </div>
                        </div>
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                            lg="12"
                        >
                            <!-- Totals -->
                            <div class="data-group totals">
                                <div class="data-group__label">Totals</div>
                                <div class="data-group__data totals__section">
                                    <div class="totals__row">
                                        <div class="totals__label">Lines</div>
                                        <div class="totals__amount">
                                            {{ totals.lines }}
                                        </div>
                                    </div>
                                    <div class="totals__row">
                                        <div class="totals__label">Units</div>
                                        <div class="totals__data">
                                            {{ totals.units }}
                                        </div>
                                    </div>
                                    <div class="totals__row">
                                        <div class="totals__label">Value</div>
                                        <div class="totals__data">
                                            {{ FormatUtil.toCurrency( totals.value ) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <!-- Generate Button -->
            <div
                v-if="isCreate"
                class="summary-detail__actions"
            >
                <ClButton
                    class="button-generate"
                    :rounded="false"
                    :disabled="!readyToSubmit"
                    :loading="loading"
                    type="primary"
                    @click="generateButtonHandler()"
                >
                    Generate
                </ClButton>
            </div>
        </v-form>
    </v-card>
</template>
<script>
// Components
import DropdownLocationList from '@/views/common/dropdowns/DropdownLocationList';
import DropdownPrimarySupplier from '@/components/dropdowns/DropdownPrimarySupplier';

// Utils
import ValidationUtil from '@/utils/ValidationUtil';

// Constants
import RRA_STATUS_OBJECT from '@/constants/rras/statusList';
import FormatUtil from '@/utils/FormatUtil';

export const TOTAL_PROPERTIES = [ 'lines', 'value', 'units' ];

export default {
    name: 'RRASummaryDetail',
    components: {
        DropdownLocationList,
        DropdownPrimarySupplier,
    },
    props: {
        isCreate: {
            type: Boolean,
            required: false,
            default: false,
        },
        isNotesDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        isStatusDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        totals: {
            type: Object,
            required: false,
            default: () => ( {
                lines: 0,
                value: 0,
                units: 0,
            } ),
            validator: ( val ) => (
                TOTAL_PROPERTIES.filter( ( propertyName ) => !Object.keys( val ).includes( propertyName ) ).length === 0
            ),
        },
        rraNumber: {
            type: String,
            required: false,
            default: '(Generated)',
        },
        notes: {
            type: String,
            required: false,
            default: '',
        },
        createDate: {
            type: String,
            required: false,
            default: FormatUtil.epochToSimpleString( Date.now() ),
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        location: {
            type: Object,
            required: false,
            default: () => ( {} ),
        },
        readyToSubmit: {
            type: Boolean,
            required: false,
            default: false,
        },
        supplier: {
            type: Object,
            required: false,
            default: () => ( {} ),
        },
        status: {
            type: String,
            required: false,
            default: 'DRAFT',
            validator: ( val ) => Object.values( RRA_STATUS_OBJECT ).includes( val ),
        },
    },
    data() {
        return {
            valid: false,
            rules: ValidationUtil,
            FormatUtil,
        };
    },
    computed: {
        /** @returns { Object[] } */
        statusList() {
            return Object.keys( RRA_STATUS_OBJECT ).map( ( key ) => ( {
                value: RRA_STATUS_OBJECT[ key ],
                text: key,
            } ) );
        },
    },
    async beforeMount() {
        await this.$store.dispatch( 'GlobalResources/init' );
    },
    methods: {
        validate() {
            this.$refs.form.validate();
            return this.valid;
        },
        generateButtonHandler() {
            if ( !this.validate() ) {
                return;
            }
            this.$emit( 'generate' );
        },
    },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
.data-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 2rem;
    &:first-child {
        margin-top: 1rem;
    }

    &__label {
        flex: 0 0 10rem;
        font-weight: bold;
    }
    &__data {
        flex-grow: 1;

        .v-input--checkbox {
            margin-top: 0;
            padding-top: 0;
            &:not(:first-child) {
                margin-top: 2rem;
            }
        }
    }
}
.checkbox-group {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    &__icon {
        margin-right: .5rem;
    }
}

.supplier__note {
    background-color: #f2f2f2;
    height: 155px;
    padding: 1rem;
    overflow: auto;
    border-radius: 4px;
}

.summary-detail {
    margin-bottom: 1rem;

    .totals {
        // 79em = 1264px
        // Margin top should only happen above that width
        @media screen and (min-width: 79em) {
            margin-top: 7rem;
        }

        &__section {
            display: flex;
            flex-direction: column;
        }

        &__row {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin: 1rem 0;
            &:first-child {
                margin-top: 0;
            }
        }
    }
}

.summary-detail__actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.5rem;

    .button-generate {
        margin: 1rem;
    }
}
</style>
